$primarycolor: #0d381f;
$secondarycolor: #ed1944;
.faq {
  // background: #ececec 0% 0% no-repeat padding-box;
  background: #ffffff;
  .faq_text h1 {
    padding-bottom: 30px;
    text-align: left;
    font: normal normal normal 'Roboto';
    font-size: 55px;
    letter-spacing: 1.44px;
    color: $primarycolor;
    opacity: 1;
  }
}
.faq_content {
  padding: 70px 0 70px 0;
}

.left_list {
  float: left;
  width: 47%;
}
.right_list {
  padding-left: 30px;
  float: right;
  width: 50%;
}
// List
.faq_list ol {
  padding: 0;
  counter-reset: item;
  list-style-type: none;
}
.faq_list ol li {
  display: block;
  font: normal normal normal 'Roboto';
  font-size: 20px;
  letter-spacing: 0.45px;
  color: #737373;
  p {
    // font: normal normal normal 17px/27px Roboto;
    font: normal normal normal 'Roboto';
    font-size: 15px;
    letter-spacing: 0px;
    color: #999999;
    opacity: 1;
  }
}
// .faq_list ol li:before {
//   content: "0" counter(item) " - ";
//   counter-increment: item;
// }

@media screen and (max-device-width: 768px) {
  .left_list {
    float: left;
    width: 100%;
  }
  .right_list {
    padding-left: 0;
    float: right;
    width: 100%;
  }
  .faq_content {
    padding: 70px 30px 70px 30px;
  }
}

@media screen and (max-device-width: 425px) {
  .faq {
    .faq_content {
      padding: 70px 20px 70px 20px;
    }
    .faq_text h1 {
      text-align: center;
      font-size: 40px;
    }
  }
}
