$primarycolor: #04381c;
$secondarycolor: #ed1944;

.latest_offers {
  background-color: #ffffff;
  padding: 57px 40px;
  display: block;

  .title {
    padding-bottom: 40px;
    margin-left: -40px;
    h1 {
      text-align: left;
      font: normal normal normal 'Roboto';
      font-size: 55px;
      letter-spacing: 1.44px;
      color: $primarycolor;
      opacity: 1;
    }
  }

  .card_list {
    display: inline-block;
    border-radius: 5px;
    margin-right: 10px;
    width: 200px;
    height: 289px;
    background-color: #fff;
  }

  a .card-img-top img {
    // background-image: url(../../images/3-min.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // width: 170px;
    width: 100%;
    // height: 120px;
    height: 190px;
    // height: auto;

    // padding: 17px;

    opacity: 1;
    border-radius: 5px 5px 0 0;
    object-fit: cover;
  }

  //   CARD BODY
  .card-body {
    padding: 10px 10px 0 10px;
    font-size: 11px;
    // font-family: 'Open Sans';
    text-align: center;

    h5 {
      // text-align: left;
      // font: normal normal medium 'Digitalt';
      font: normal normal normal 16px/20px 'Roboto';
      // font-weight: normal;
      font-weight: bold;
      // font-size: 16px;
      letter-spacing: 0px;
      // color: #173142;
      color: $primarycolor;
      opacity: 1;
      margin: 0;
    }
    p {
      // text-align: left;
      // font: normal normal medium 'Digitalt';
      font-size: 12px;
      letter-spacing: 0px;
      color: #aeaeae;
      opacity: 1;
      margin: 0;
      font-weight: bold;
    }
    .card_des {
      // p {
      //   // float: left;

      // }
      span {
        padding-top: 10px;
        font-weight: bold;
        float: right;
        font: normal normal medium 16px/21px 'Digitalt';
        font-size: 17.5px;
        letter-spacing: 0px;
        // color: #7dad3f;
        color: $secondarycolor;
        opacity: 1;
        position: absolute;
        bottom: 3%;
        right: 5%;
      }
    }
  }
}
.modal_content {
  // text-align: center;
  // position: absolute;
  // top: 50%;
  // left: 35%;
  // -ms-transform: translateY(-50%);
  // transform: translateY(-50%);
  // p {
  //   font-size: 20px;
  //   font-weight: bold;
  // }
  // a {
  //   padding: 10px;
  // }

  .modal_title {
    width: 100%;
    height: 570px;
    // background-color: #173142;
    background-color: $primarycolor;
    // background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    //   url('../../images/hero_img.jpg');
    // // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    // position: relative;

    img {
      padding-top: 120px;
      width: 150px;
      height: auto;
    }
  }

  .download_app {
    padding-top: 110px;
    // background: #ececec 0% 0% no-repeat padding-box;
    a img {
      margin-top: -40px;
      width: 130px;
      height: auto;
    }

    // a {
    //   text-transform: uppercase;
    //   text-decoration: none;
    //   display: inline-block;
    //   font-size: 14px;
    //   border: 1px solid #34aeb4;
    //   font-weight: 600;
    //   line-height: 26px;
    //   padding: 9px 20px;
    //   box-shadow: none;
    //   text-shadow: none;
    //   letter-spacing: 1px;
    //   border-radius: 10px;
    //   color: #173142;
    //   margin-left: 7px;
    //   margin-right: 7px;
    //   background-color: transparent;
    //   &:hover {
    //     border: 2px solid #173142;
    //     color: #ffffff;
    //     transition: ease-in-out;
    //     background-color: #173142;
    //   }
    // }
    p {
      color: #000;
      font-size: 20px;
      padding-top: 30px;
      letter-spacing: 1px;
      font-weight: bold;
    }
    // a {
    //   font-size: 20px;
    //   text-decoration: none;
    //   // color: #173142;
    // }
  }
}
// /* Carousel */
.react-multiple-carousel__arrow--right {
  right: calc(-3.5% + 1px) !important;
  position: absolute;
  background: transparent;
  height: auto;
  // height: 30%;
  // background: transparent linear-gradient(270deg, #2aced7 0%, #1d223e0f 100%) 0%
  //   0% no-repeat padding-box !important;
  //   opacity: 1;
  // width: 90px;
  // border-radius: 10px !important;
  //   ::before {
  //     text-align: right !important;
  //   }
}
.react-multiple-carousel__arrow--left {
  background: transparent;
  opacity: 1;
  left: calc(-4% + 1px) !important;
  height: auto;
  // height: 30%;
  // background: transparent linear-gradient(100deg, #2aced7 0%, #1d223e0f 100%) 0%
  //   0% no-repeat padding-box !important;

  //   width: 90px;
  // border-radius: 10px !important;
  //   font-size: 40px;
  //   ::before {
  //     text-align: left !important;
  //   }
}
.react-multi-carousel-list {
  position: static;
  text-align: center;
}
.react-multiple-carousel__arrow::before {
  color: lightslategray;
  font-size: 30px;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(000, 000, 000, 0.5) !important;
}

// MEDIA QUERIES
@media screen and (max-width: 1200px) {
  .latest_offers {
    .card_list {
      width: 175px;
      height: 285px;
    }
    a .card-img-top img {
      // width: 160px;
      width: 100%;
      height: 190px;
      // height: auto;
    }
    .title {
      margin-left: 0;
    }
    // .react-multiple-carousel__arrow--right {
    //   height: 30%;
    //   right: calc(3% + 1px) !important;
    // }
  }
}

@media screen and (max-width: 1024px) {
  .latest_offers {
    .title {
      h1 {
        font-size: 50px;
      }
    }
    .card_list {
      width: 200px;
      height: 300px;
    }
    a .card-img-top img {
      // width: 160px;
      width: 100%;
      height: 180px;
      // height: auto;
    }
  }
}

@media screen and (max-width: 991px) {
  .latest_offers {
    .card_list {
      width: 190768 px;
      height: 285px;
    }
    a .card-img-top img {
      // width: 145px;
      width: 100%;
      height: 155px;
      // height: auto;
    }

    .react-multiple-carousel__arrow--right {
      height: auto;
      right: calc(-5% + 1px) !important;
    }
    .react-multiple-carousel__arrow--left {
      height: auto;
      left: calc(-6% + 1px) !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .latest_offers {
    .card_list {
      width: 190px;
      height: 280px;
    }
    a .card-img-top img {
      // width: 145px;
      width: 100%;
      height: 170px;
      // height: auto;
    }
    .react-multiple-carousel__arrow--right {
      right: calc(-5% + 1px) !important;
      position: absolute;
      background: transparent;
      height: auto;
    }
    // .card-body h5 {
    //   height: 42px;
    // }
  }
}

@media screen and (max-width: 766px) {
  .moto {
    margin-top: 0 !important;
  }
  .hero_section .hero_content .hero_text {
    padding-top: 40px !important;
  }
}

@media screen and (max-width: 495px) {
  .latest_offers {
    .title {
      h1 {
        font-size: 40px;
        text-align: center;
      }
    }
    .card_list {
      width: 165px;
      height: 253px;
    }
    a .card-img-top img {
      // width: 130px;
      width: 100%;
      height: 145px;
      // height: auto;
    }
    .card-body h5 {
      // height: 42px;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    width: 300px !important;
    height: 240px !important;
  }

  .modal_content .modal_title img {
    padding-top: 95px;
    margin-left: 7px;
    width: 80px;
    height: auto;
  }
  .modal_content .download_app {
    padding-top: 50px;
    p {
      font-size: 12.5px;
    }
  }
  .modal_content .download_app {
    padding-left: 0;
    a {
      img {
        width: 90px;
        // float: left;
      }
    }
  }
  .moto {
    display: none;
  }
  .hero_section .hero_content .hero_text {
    padding-top: 100px !important;
  }
}

@media screen and (max-width: 375px) {
  .latest_offers {
    .card_list {
      width: 127px;
      height: 240px;
    }
    a .card-img-top img {
      // width: 147px;
      width: 100%;
      height: 150px;
      // height: auto;
    }
    .card-body h5 {
      // height: 42px;
      display: -webkit-box;
      // -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 320px) {
  .latest_offers {
    .card_list {
      width: 110px;
      height: 220px;
    }
    a .card-img-top img {
      // width: 110px;
      width: 100%;
      height: 110px;
      // height: auto;
    }
  }
}
