$primarycolor: #0d381f;
$secondarycolor: #ed1944;
.drop_form {
  // padding-bottom: 57px;
  // background: #ececec 0% 0% no-repeat padding-box;
  background-color: #fff;
  .error_msg {
    color: red;
    padding: 0;
  }
  .comment_form {
    margin: 57px 0 57px 0;

    h1 {
      padding-bottom: 70px;
      text-align: left;
      font: normal normal normal 'Roboto';
      font-size: 55px;
      letter-spacing: 1.44px;
      color: $primarycolor;
      opacity: 1;
    }
  }

  .contact_result {
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
  }

  .comment_form form {
    width: 80%;
    float: right;
    padding-right: 57px;
  }

  .input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    border-bottom: 2px solid #9b9b9b;
  }
  .icon {
    padding: 10px 0;
    color: #9b9b9b;
    font-size: 20px;
    text-align: center;
  }

  .form-control {
    outline: none;
    border: none;
    background-color: transparent !important;
  }

  .textarea {
    padding-top: 15px;
    outline: none;
    textarea {
      resize: none;
      width: 100%;
      height: 140px;
      background-color: #ececec;
      border: 1px solid #707070;
      color: #707070;
      // padding-left: 40px;
      outline-color: #504f4f;
    }
  }

  .btn_send {
    float: right;
    padding-top: 15px;
    padding-bottom: 57px;

    button {
      width: 200px;
      height: 50px;
      background: $primarycolor 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #7dad3f;
      border: 1px solid #7dad3f;
      border-radius: 40px;
      color: #fff;
      opacity: 1;
      outline: none;
      &:disabled {
        background-color: #999999;
      }
    }
  }

  .info_right {
    // padding-top: 0px;
    padding-left: 57px;
    position: absolute; /* 2 */
    top: 50%; /* 3 */
    transform: translate(0, -50%);
    color: #999999;
    .contact_info {
      margin-top: -90px;
    }
    p {
      font: normal normal normal 'Roboto';
      font-size: 20px;
      letter-spacing: 0.45px;
      margin-bottom: 1px;
    }
    img {
      width: 230px;
      // height: 70px;
      height: auto;
      margin-bottom: 20px;
      margin-left: -45px;
    }
  }

  // MEDIA QUERIES
  @media screen and (max-width: 767px) {
    .drop_form {
      padding: 0 0 60px 0;
      // height: 170vh;
      .comment_form {
        margin: 57px 0px 57px 20px;
        form {
          width: 100%;
          padding-right: 0px;
        }
        h1 {
          font-size: 45px;
          padding-bottom: 70px;
        }
      }
    }
    .info__right {
      display: none;
      .info_right {
        padding-top: 300px;

        img {
          width: 100px;
          height: 70px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .drop_form {
    padding: 0;
    .comment_form {
      margin: 57px 0px 57px 40px;
      form {
        width: 100%;
        padding-right: 30px;
        .form-group {
          .btn_send button {
            width: 150px;
            height: 35px;
          }
        }
      }
      h1 {
        font-size: 40px;
        padding-bottom: 40px;
      }
    }
    .info_right {
      margin-top: 0px;
      display: none;
    }
  }
}

@media screen and (max-width: 375px) {
  .drop_form {
    .comment_form {
      margin: 57px 0px 57px 40px;
      form {
        width: 100%;
        padding-right: 30px;
        .form-group {
          .btn_send button {
            width: 150px;
            height: 35px;
          }
        }
      }
      h1 {
        font-size: 40px;
        padding-bottom: 40px;
      }
    }
    .info_right {
      margin-top: 0px;
    }
  }
}
