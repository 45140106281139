$primarycolor: #0d381f;
$secondarycolor: #ed1944;
.furnitoret {
  background: #fff 0% 0% no-repeat padding-box;
  padding: 40px 0 40px 0;
  h1 {
    padding-bottom: 30px;
    text-align: left;
    font: normal normal normal 'Roboto';
    font-size: 55px;
    letter-spacing: 1.44px;
    color: $primarycolor;
    opacity: 1;
  }
  .img_donatoret {
    // flex: 0 0 14.2%;
    // max-width: 14.2%;
    display: inline-block;
    width: 100%;

    img {
      width: 12.2%;
      height: auto;
      // height: 140px;
      object-fit: cover;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 2%;
      //   margin-left: 1%;
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .img_donatoret img {
    width: 20% !important;
    height: auto;
    // margin-left: 9%;
    margin-right: 5% !important;
    padding-bottom: 5%;
  }
}
@media screen and (max-width: 425px) {
  .frunitoret {
    h1 {
      text-align: center;
    }
    .img_donatoret img {
      width: 40% !important;
      height: auto;
      margin-left: 5%;
      margin-right: 5% !important;
      padding-bottom: 5%;
    }
  }
}
