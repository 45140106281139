$primarycolor: #0d381f;
$secondarycolor: #ed1944;
.statistics {
  width: 100%;
  min-height: 500px;
  padding-top: 40px;
  padding-bottom: 40px;
  display: block;
  background: #ececec 0% 0% no-repeat padding-box;
  text-align: center;

  .chart_container {
    width: 40%;
    height: 100px;
    float: left;
    margin-bottom: 40px;
    p {
      float: right;
      width: 60%;
    }
  }
  .chart_text {
    font-size: 17px;
  }

  .stat_4 {
    // padding-top: 20px;
    padding-bottom: 40px;
    // padding-left: 20px;
    // text-align: center;
    .count_up {
      font-size: 20px;
      color: #173142;
      margin: 0;
    }
    p {
      font-size: 17.5px;
    }
  }

  .stat_text {
    // text-align: center;
    // padding: 0 0 40px 0;
    color: #173142;
    p {
      font-size: 17.5px;
      .uppercase {
        text-transform: uppercase !important;
        font-weight: bold;
      }
    }
    .count_up {
      font-size: 20px;
      color: #173142;
    }
  }

  h1 {
    text-align: left;
    font: normal normal normal 'Roboto';
    font-size: 55px;
    letter-spacing: 1.44px;
    color: $primarycolor;
    opacity: 1;
    padding-bottom: 50px;
  }

  // react-fullscreen-image
  .fullscreen-group.fullscreen-group--expanded button.fullscreen-toggle {
    outline: none;
  }

  .fullscreen-group.fullscreen-group--expanded button.fullscreen-exit-btn {
    outline: none;
  }

  ul.images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(23%, 1fr));
    grid-gap: 15px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.images > li {
    position: relative;
    padding-top: 66%;
  }

  ul.images > li img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    // padding: 10px;
  }

  .fullscreen-group.fullscreen-group--expanded::before {
    background-color: rgba(000, 000, 000, 0.5) !important;
  }
  .fullscreen-image {
    padding: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .statistics {
    padding: 57px 40px;
    h1 {
      font-size: 50px;
    }
  }
}

// @media screen and (max-width: 991px) {
// }

@media screen and (max-width: 768px) {
  .statistics {
    ul.images {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(47%, 1fr));
      grid-gap: 15px;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}

@media screen and (max-width: 425px) {
  .statistics {
    padding: 57px 20px;
    h1 {
      font-size: 40px;
      text-align: center;
      margin-bottom: 0px;
      padding-bottom: 40px;
    }
    ul.images {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      grid-gap: 20px;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}
