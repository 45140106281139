$primarycolor: #04381c;
$secondarycolor: #ed1944;

.single_post {
  // padding: 57px 100px 150px 100px;
  .row {
    margin-right: 0;
  }
  .logo {
    padding: 0px 60px 0px 100px;
    margin-bottom: 60px;
    background-color: $primarycolor;
    a img {
      width: 140px;
      height: auto;
      float: left;
      // margin-bottom: 60px;
    }
  }

  .description {
    padding-top: 40px;
    .post_title {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        // color: #636363;
        color: $primarycolor;
        clear: both;
        font-weight: bold;
        font-size: 30px;
        font-family: 'Ubuntu', sans-serif;
        margin-bottom: 0;
      }
    }

    .date {
      padding-bottom: 30px;
      p {
        color: #000000;
        font-size: 15px;
        font-weight: 500;
        font-family: 'Ubuntu', sans-serif;
        padding-left: 5px;
        margin-bottom: 15px;
      }
    }

    .post_img img {
      padding-bottom: 30px;
      display: block;
      max-width: 100%;
      // width: 100%;
      // height: 650px;
      // height: auto;
      height: 500px;
      object-fit: cover;
      // margin: auto;
      float: left;
      padding-right: 40px;
    }

    p {
      font-size: 18px;
      // color: rgb(131, 130, 130);
      color: #999;
      margin: 0 0 15px;
      font-family: 'Ubuntu', sans-serif;
      line-height: 22px;
      margin-bottom: 60px;
    }
  }

  .navbar__ {
    .navbar {
      padding-top: 0;
      // margin-top: -25px;
      .menu-bars {
        // color: gray;
        color: #fff;
      }
    }
    .nav-menu {
      border-left: 1px solid #000;
    }
  }
}

@media screen and (max-width: 767px) {
  .single_post {
    .description {
      padding-left: 40px;
    }
  }
}

@media screen and (max-width: 425px) {
  .single_post {
    .logo {
      padding: 10px 0 0 30px;
      margin-bottom: 0;
    }

    .description {
      padding-left: 20px;
      margin: 0 0 5px 15px;

      .post_img img {
        padding-bottom: 30px;
        display: block;
        // max-width: 100%;
        width: 100%;
        height: 100%;
        // height: auto;
        object-fit: cover;
        float: left;
        // margin: auto;
        padding-right: 0;
      }
    }
  }
}
