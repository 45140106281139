$primarycolor: #04381c;
$secondarycolor: #ed1944;

.view_blog {
  .paginationBttns {
    // width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .paginationBttns {
    a {
      padding: 10px;
      margin: 8px;
      border-radius: 5px;
      border: 1px solid $primarycolor;
      color: $primarycolor;
      cursor: pointer;
    }
  }

  .paginationBttns {
    a {
      &:hover {
        color: white;
        background-color: $primarycolor;
      }
    }
  }

  .paginationActive {
    a {
      color: white;
      background-color: $primarycolor;
    }
  }

  .paginationDisabled {
    a {
      display: none;
    }
  }

  .row {
    margin-right: 0 !important;
  }
  .banner {
    // padding: 0;
    // background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.747)),
    // url('../../images/hero_img.jpg');
    // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    // display: block;
    // width: 100%;
    // height: 500px;
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    // position: relative;
    // background: #173142 0% 0% no-repeat padding-box;
    // opacity: 0.83;
    // h1 {
    //   text-align: center;
    //   color: #ffffff;
    //   padding-top: 150px;
    //   text-transform: uppercase;
    //   letter-spacing: 1px;
    //   font-weight: bold;
    //   font-size: 30px;
    // }

    background-color: $primarycolor;
    .logo {
      padding: 0px 60px 100px 60px;
      margin-bottom: 30px;
      // background-color: #173142;
      a img {
        width: 140px;
        height: auto;
        float: left;
        // margin-bottom: 30px;
      }
    }
  }

  .posts {
    padding: 0 100px;
    margin-top: 60px;

    .row {
      margin-right: 0 !important;
    }
    .post_title {
      padding-top: 35px;
      // font-family: 'Ubuntu', sans-serif;
      font-family: 'Roboto', sans-serif;
      // font-weight: 400;
      text-transform: uppercase;
      color: $primarycolor;
      font-size: 12px;
      font-weight: normal;

      h1 {
        margin-bottom: 0;
        font-size: 25px;
        font-family: 'Roboto', sans-serif;
      }
    }

    .date {
      color: rgb(161, 160, 160);
      font-size: 15px !important;
      padding-bottom: 20px;
      svg {
        margin-top: -3px;
      }
    }

    .all_posts {
      padding-bottom: 70px;

      p {
        line-height: 25px;
        font-size: 18px;
        color: #999;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      a {
        text-decoration: none;
        color: rgb(43, 43, 43);
        font-family: 'Roboto', sans-serif;
        font-size: 14px;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          background-color: rgb(211, 210, 210);
          width: 15%;
          height: 1px;
          display: block;
          left: 15px;
          margin-top: 5px;
        }
        &:hover {
          color: $secondarycolor;
        }
      }
    }
  }

  img {
    height: 400px;
    // height: auto;
    // height: 100%;
    object-fit: cover;
    width: 100%;
    // border-radius: 5px 5px 0 0;
  }

  .navbar__ {
    .navbar {
      padding-top: 0;
      // margin-top: -30px;
      .menu-bars {
        // color: gray;
        color: #fff;
      }
    }
    .nav-menu {
      border-left: 1px solid #000;
    }
  }

  // .navbar__ {
  //   .navbar {
  //     .menu-bars {
  //       // color: gray;
  //     }
  //   }
  // }
}

@media screen and (max-width: 425px) {
  .view_blog {
    .posts {
      padding: 0 35px;
      margin-top: 60px;
      padding-right: 0;
    }
    .banner .logo {
      padding: 10px 0 0 35px;
      // margin-bottom: 30px;
    }
    img {
      height: 250px;
    }
    .paginationBttns a {
      padding: 3px;
      margin: 2px;
      border-radius: 5px;
    }
  }
}
