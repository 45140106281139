$primarycolor: #0d381f;
$secondarycolor: #ed1944;
.blog {
  //   background: rgb(235, 231, 231);
  background: #ececec 0% 0% no-repeat padding-box;
  padding: 57px 0;

  .blog_title h1 {
    padding-bottom: 30px;
    text-align: left;
    font: normal normal normal 'Roboto';
    font-size: 55px;
    letter-spacing: 1.44px;
    color: $primarycolor;
    opacity: 1;
  }

  .view_blog {
    text-align: center;
    padding: 50px 30px 20px 30px;
    a {
      text-transform: uppercase;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;
      border: 1px solid;
      font-weight: 500;
      line-height: 26px;
      padding: 9px 18px;
      box-shadow: none;
      text-shadow: none;
      font-weight: 600;
      letter-spacing: 1px;
      border-radius: 5px;
      color: #000;
      &:hover {
        // color: #2aced7;
        color: $secondarycolor;
        transition: ease-in-out;
      }
    }
  }

  .blog_posts {
    background-color: #fff;
    padding: 0;
    // margin: 0 15px;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
      0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    border-radius: 5px 5px 0 0;
    margin-bottom: 20px;
    height: 500px;

    .description {
      padding: 15px 20px;
      margin-bottom: 20px;
      h2.title {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 400;
        // text-transform: uppercase;
        font-size: 25px;
        margin-bottom: 0;
        font-family: 'Roboto Condensed', sans-serif;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      p {
        line-height: 25px;
        font-size: 18px;
        color: #999;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-family: 'Roboto', sans-serif;
      }

      .date {
        font-size: 14px;
        font-weight: normal;
        font-family: 'Roboto Condensed', sans-serif;
      }

      a {
        text-decoration: none;
        color: rgb(46, 45, 45);
        font-family: 'Roboto', sans-serif;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          background-color: rgb(190, 189, 189);
          // width: 20%;
          width: 100%;
          height: 1px;
          display: block;
          // left: 35px;
          margin-top: 10px;
        }
        &:hover {
          // color: #2aced7;
          color: $secondarycolor;
        }
      }

      .read_more {
        margin-bottom: 10%;
        position: absolute;
        bottom: 5%;
      }
    }

    img {
      // max-width: 100%;
      width: 100%;
      // height: auto;
      height: 253px;
      object-fit: cover;
      border-radius: 5px 5px 0 0;
    }
  }
  // .vv {
  //   background-image: url('../../images/6-min.jpg');
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   position: relative;
  //   margin-right: 15px;
  //   img {
  //     opacity: 0.9;
  //   }
  // }
}

// test
// .blog_post2 {
//   width: 300px;
//   height: 300px;
//   .post_img {
//     width: 300px;
//     height: 300px;
//     object-fit: cover;
//   }
// }
@media screen and (max-width: 425px) {
  .blog {
    //   background: rgb(235, 231, 231);
    background: #ececec 0% 0% no-repeat padding-box;
    padding: 57px 0;
    .blog_title h1 {
      padding-bottom: 30px;
      font-size: 40px;
      text-align: center;
    }
  }
}
