$primarycolor: #04381c;
$secondarycolor: #ed1944;

// * SIDEBAR **************
.navbar {
  float: right;
  padding-top: 30px;
  padding-right: 0;
  // display: block;
  // position: absolute;
  // top: 0;
  // right: 0;
  // transform: translate(-100%, 60%);
  font-size: 1.8rem;
  cursor: pointer;
  color: #fff;
  // height: 80px;
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;
}

.nav-menu {
  background-color: $primarycolor;
  width: 100%;
  height: 100vh;
  // display: flex;
  // justify-content: center;
  position: fixed;
  // right: -70%;
  left: 70%;
  top: 0;
  transition: 850ms;
  z-index: 99;
}
.active {
  left: 100%;
  transition: 350ms;
}
.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  height: 40px;
}
.nav-text a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  width: 20%;
  height: 100%;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}
.nav-text a:hover {
  // background-color: aquamarine;
  // background-color: #173142;
  background-color: $secondarycolor;
  padding: 5px;
}
.nav-menu-items {
  width: 100%;
}
.navbar-toggle {
  background-color: $primarycolor;
  width: 22%;
  height: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.menu-bars {
  color: #fff;
  font-size: 27px;
  padding-top: 70px;
  :hover {
    color: $secondarycolor;
  }
}
.menu_icon {
  padding-top: 40px;
  padding-right: 5px;
  color: #fff;
  :hover {
    color: $secondarycolor;
  }
}
.list_items {
  padding-top: 100px;
}
span {
  margin-left: 16px;
}

.social_media {
  // padding-top: 10%;
  width: 30%;
  position: absolute;
  bottom: 9%;

  ul {
    text-align: center;
    padding: 0;
    a {
      text-decoration: none;
      li {
        list-style: none;
        display: inline-block;
        // padding-right: 30px;
        cursor: pointer;
        color: #fff;
        vertical-align: text-bottom;
        padding: 0 10px;
        font-size: 15px;
        &:hover {
          color: $secondarycolor;
          padding: 3px;
          font-weight: bold;
        }
      }
    }
  }
}

// MEDIA QUERIES

@media screen and (max-device-width: 978px) {
  .nav-text a {
    font-size: 16px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .nav-menu {
    left: 60%;
  }
  .active {
    left: 100%;
    transition: 350ms;
  }

  span {
    margin-left: 0;
  }

  .social_media {
    padding-top: 10%;
    ul li {
      list-style: none;
      display: inline-block;
      // padding-right: 30px;
      cursor: pointer;
      color: #fff;
      vertical-align: text-bottom;
      padding: 0 7spx;
      font-size: 12px;
    }
  }
  .list_items {
    padding-top: 70px;
    // padding-left: 30px;
  }
  .nav-text a {
    font-size: 16px;
    width: 30%;
  }
  .social_media {
    padding-top: 10%;
    width: 40%;
    font-size: 12px;
    ul {
      //   padding-left: 10px;
      li {
        list-style: none;
        display: inline-block;
        // padding-right: 30px;
        cursor: pointer;
        color: #fff;
        vertical-align: text-bottom;
        padding: 0 7px;
      }
    }
  }
  .navbar-toggle {
    width: 31%;
  }
}

@media screen and (max-width: 425px) {
  .navbar__ {
    .nav-menu {
      width: 100%;
      left: 40%;
      .nav-menu-items {
        padding-left: 20px;

        .list_items {
          .nav-text a {
            width: 55%;
          }
        }
      }
    }
    .active {
      left: 100%;
      transition: 350ms;
    }
    .navbar-toggle {
      width: 50%;
    }
    .social_media {
      width: 60%;
    }
  }
}
