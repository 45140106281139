$primarycolor: #0d381f;
$secondarycolor: #ed1944;

.our_story {
  background: #ececec 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: center;
}

.left_content {
  padding-top: 70px;

  .story_text {
    width: 80%;

    .story--title {
      text-align: left;
      font: normal normal normal 'Roboto';
      font-size: 60px;
      letter-spacing: 1.44px;
      color: $primarycolor;
      opacity: 1;
      font-weight: 500;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 30px;
      // color: #7dad3f;
      color: $secondarycolor;
      float: left;
      padding-top: 20px;
      font-size: 25px;
      text-align: left;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        // background-color: slategrey;
        background-color: $primarycolor;
        width: 12%;
        height: 3px;
        display: block;
        left: 15px;
        margin-top: 15px;
      }
    }

    p {
      float: left;
      text-align: left;
      color: #999999;
    }
  }

  .phone_img {
    margin-right: 70px;
  }

  img {
    margin: 57px 0 57px 0;
    width: 300px;
    height: 540px;
    border-radius: 57px;
    justify-content: center;
    object-fit: cover;
  }
}

.right_content {
  padding-top: 200px;
  text-align: center;

  .story_text {
    padding-top: 20px;
    width: 100%;
    padding-left: 100px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 30px;
      // color: #7dad3f;
      color: $secondarycolor;
      float: left;
      padding-top: 20px;
      font-size: 25px;
      text-align: left;
      &:after {
        content: '';
        position: absolute;
        // background-color: slategrey;
        background-color: $primarycolor;
        width: 12%;
        height: 3px;
        display: block;
        left: 115px;
        margin-top: 15px;
      }
    }

    p {
      float: left;
      text-align: left;
      color: #999999;
    }
  }

  .btn_partners {
    // padding-top: 57px;
    padding-left: 0;
    display: inline-flex;
    margin-bottom: 60px;
    float: left;

    a img {
      width: 150px;
      height: auto;
      margin-right: 5px;
      margin-top: 5px;
    }

    // button {
    //   width: 200px;
    //   height: 50px;
    //   box-shadow: 0px 3px 6px #2aced7;
    //   border: 3px solid #2aced7;
    //   opacity: 1;
    //   background-color: #2aced7;
    //   border-radius: 40px;
    //   color: #eefff9;
    //   font: normal normal medium 20px/26px 'Roboto';
    //   letter-spacing: 0.32px;
    //   outline: none;
    // }

    // .btn_playstore {
    //   margin-right: 10px;
    // }
  }

  .phone__img {
    margin-left: 70px;
    display: block;
    img {
      width: 300px;
      height: 540px;
      border-radius: 57px;
      justify-content: center;
      object-fit: cover;
      margin-bottom: 20px;
    }
  }
  .phone__imgg {
    display: none;
  }
}

.outer {
  width: 1px;
  overflow: hidden;
}
.inner {
  position: absolute;
  width: 1px;
  height: 60%;
  background: $primarycolor;
  top: 20%;
  bottom: 20%;
  margin-left: 97%;
}

// Media Queries

@media screen and (max-width: 1024px) {
  .our_story {
    .left_content {
      .story_text {
        .story--title {
          font-size: 50px;
        }
      }
    }
    .right_content {
      .btn_partners {
        float: left;
        button {
          width: 150px;
          height: 40px;
        }
      }
      .phone__img {
        margin-left: 40px;
      }

      .story_text {
        padding-left: 50px;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          &:after {
            left: 67px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .our_story {
    .left {
      flex: 0 0 100%;
      max-width: 100%;
      .outer {
        display: none;
      }
    }
    .left_content {
      padding-top: 70px;
      padding-left: 30px;

      .story--title {
        font-size: 50px;
        width: 100%;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 20px;
        &:after {
          width: 12%;
          left: 45px;
        }
      }

      .phone_img {
        margin-right: 10px;
      }

      img {
        width: 270px;
        height: 500px;
      }
    }

    .right_content {
      padding-top: 30px;
      .phone__img {
        display: none;
      }
      .phone__imgg {
        display: block;
        margin-left: 10px;

        img {
          width: 270px;
          height: 500px;
          margin-top: 40px;
          border-radius: 57px;
          justify-content: center;
          object-fit: cover;
          margin-bottom: 20px;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 20px;
        &:after {
          width: 12%;
          left: 55px !important;
        }
      }

      .story_text {
        width: 95%;
        padding-left: 40px;
        .wp-video {
          margin: auto;
        }
        video#video-0-1 {
          height: auto;
          margin: auto;
        }
      }

      .btn_partners {
        float: none;
        // margin-left: -30px;
        padding-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 498px) {
  .our_story .right_content .story_text {
    .wp-video {
      width: 300px !important;
      video#video-0-1 {
        width: 300px !important;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .our_story {
    .outer {
      display: none;
    }

    .left_content {
      padding-left: 20px;

      .story_text {
        width: 90%;
      }
      .phone_img {
        img {
          margin: 57px 0 0 -10px;
          width: 250px;
          height: 470px;
        }
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 20px;
        &:after {
          width: 20%;
          left: 35px;
        }
      }
    }

    .right_content {
      padding-top: 57px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 20px;
        &:after {
          width: 20%;
          left: 35px !important;
        }
      }

      .story_text {
        padding-left: 20px;
        .wp-video {
          margin: auto;
        }
        video#video-0-1 {
          width: 300px !important;
          height: auto;
          margin: auto;
        }
      }

      .phone__imgg {
        img {
          margin: 57px 0 0 -20px;
          width: 250px;
          height: 470px;
        }
      }

      .btn_partners {
        padding-bottom: 0;
        a img {
          width: 120px;
          height: auto;
          margin-right: 5px;
          border-radius: 0;
        }
        // button {
        //   width: 110px;
        //   font-size: 14px;
        // }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .our_story .right_content .story_text {
    .wp-video {
      width: 250px !important;
      video#video-0-1 {
        width: 250px !important;
      }
    }

    .btn_partners {
      padding-bottom: 0;
      a img {
        margin-top: 30px;
      }
    }
  }
}
