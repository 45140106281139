$primarycolor: #0d381f;
$secondarycolor: #ed1944;
.aktivizimi-llogarise {
  // padding: 57px 100px 150px 100px;
  .row {
    margin-right: 0;
  }
  .logo {
    padding: 0 70px 0px 100px;
    margin-bottom: 60px;
    background-color: $primarycolor;
    a img {
      width: 140px;
      height: auto;
      float: left;
      // margin-bottom: 60px;
    }
  }
  .tekst_konfirmimi {
    text-align: center;
    p {
      // padding-left: 60px;
      font-weight: 600;
      font-size: 22px;
    }
    .text_1 {
      font-size: 20px;
    }
  }
  .navbar__ {
    .navbar {
      padding-top: 0;
      margin-top: 0;
      .menu-bars {
        // color: gray;
        color: #fff;
      }
    }
    .nav-menu {
      border-left: 1px solid #000;
    }
  }
}
