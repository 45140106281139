$primarycolor: #0d381f;
$secondarycolor: #ed1944;

.accordion_text {
  text-align: center;
}

.our_partners {
  text-align: center;
  font: normal normal normal 30px/39px 'Roboto';
  letter-spacing: 0.45px;
  color: $primarycolor;
  opacity: 1;
  font-weight: bold;
}

.partners {
  display: inline-block;
  width: 100%;

  img {
    width: 15%;
    height: auto;
    // height: 140px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 5%;
    margin-left: 5%;
  }
}

.arrow_down {
  cursor: pointer;
}

.card-body {
  padding: 1.25rem 60px;
}

.card-header {
  border: none;
  background-color: #ffffff;
}

svg.arrow_down {
  // margin-top: 20px;
  margin-top: 5px;
  font-size: 35px;
  // color: #999999;
  color: $primarycolor;
}

// Media Queries
@media screen and (max-width: 1024px) {
  .partners {
    img {
      width: 19%;
      height: auto;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 3%;
      margin-left: 3%;
    }
  }

  .menu_icon {
    padding-top: 40px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 978px) {
  .partners img {
    width: 22% !important;
    height: auto;
    margin-left: 14%;
    margin-right: 14%;
  }
}

@media screen and (max-width: 768px) {
  .partners img {
    width: 32% !important;
    height: auto;
    margin-left: 9%;
    margin-right: 9%;
  }
}

@media screen and (max-width: 425px) {
  .collapse_bar {
    .our_partners {
      text-align: center;
      font: normal normal normal 30px/39px 'Roboto';
      // letter-spacing: 0.45px;
      font-size: 30px;
      color: #0d381f;
      opacity: 1;
    }
    .partners img {
      width: 60% !important;
      height: auto;
      margin-left: 20%;
      margin-right: 20%;
    }
    .card-body {
      padding: 1.25rem 20px;
    }
  }
}
