$primarycolor: #0d381f;
$secondarycolor: #ed1944;
.footer {
  padding: 40px 0 40px 0;
  // background: #ececec 0% 0% no-repeat padding-box;
  background-color: #ececec;

  .footer_links ul {
    text-align: center;
    li {
      list-style: none;
      display: inline-block;
      padding-right: 30px;
      cursor: pointer;
      color: $primarycolor;
    }
  }
}
.footer_desc {
  text-align: center;
  p {
    color: #999999;
  }
}

@media screen and (max-width: 768px) {
  .footer_desc p {
    text-align: center;
    float: none;
    width: 100% !important;
  }
}

@media screen and (max-width: 425px) {
  .footer_desc p {
    padding: 0 5px;
  }
}
