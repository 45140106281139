$gray: #eeeeee;
$primarycolor: #0d381f;
$secondarycolor: #ed1944;

.regjistro_biznesin {
  background: $gray;
  // padding: 76px;
  // height: 104vh;
  height: auto;
  z-index: 99;
  padding-top: 4%;
  padding-left: 6%;

  .fjeshil-container .logo {
    padding: 0 6% 0 3%;
    margin-top: -50px;
    margin-left: -35px;
    img {
      width: 230px;
      height: auto;
      float: left;
    }
    .navbar__ {
      .navbar {
        margin-top: 17px;
        margin-bottom: 70px;
        .menu-bars {
          // color: gray;
          color: $primarycolor;
        }
      }
    }
  }

  h1 {
    color: $primarycolor;
  }
  .text_confirmation {
    padding-left: 40px;
    font-weight: 500;
  }
  .text_confirmation2 {
    // padding-left: 25px;
    font-weight: 500;
    font-size: 12.5px;
    padding-left: 10px;
  }
  .text_confirmation3 {
    // padding-left: 25px;
    font-weight: 500;
    font-size: 12.5px;
    padding-left: 27px;
  }
}

.regjistro_form {
  p {
    float: left;
    padding: 40px 0 0 5px;
    color: #504d4d;
  }
  form {
    // float: left;
    padding: 10px 30px;

    label {
      float: left;
      width: 100%;
    }
    select,
    input {
      word-wrap: normal;
      // width: 340px;
      padding: 5px 15px;
      background: #eeeeee 0% 0% no-repeat padding-box;
      /* border-bottom: #707070; */
      border: none;
      width: 100%;

      .select_mbiemri {
        float: right;
      }
    }
    input#agree {
      width: auto;
      float: left;
      margin-top: 5px;
    }
    label#kushtet {
      width: auto;
      margin-left: 5px;
      a {
        text-decoration: none;
        color: $primarycolor;
        :hover {
          color: #0f5e2f;
        }
      }
    }
    select:hover,
    input:hover {
      outline: 0.10000000149011612px solid #707070;
    }
    input.select_emri {
      float: left;
      width: 47%;
    }
    input.select_mbiemri {
      float: right;
      width: 47%;
    }
    select.rb_select,
    input.rb_select,
    input.select_emri,
    input.select_mbiemri {
      border-bottom: 1px solid #707070;
      color: #707070;
      font-size: 12.5px;
    }
    // }
  }
  // .regjistrohu_textarea {
  //   float: left;
  //   padding-top: 40px;
  //   label textarea {
  //     width: 100%;
  //     // height: 164px;
  //     background-color: #ffffff;
  //     color: #999999;
  //     padding: 15px;
  //     font-size: 12.5px;
  //     border-top: none;
  //     border-left: none;
  //     border-right: none;
  //     outline: none;
  //     border-bottom: 1px solid #707070;
  //   }
  // }
  textarea {
    width: 100%;
    margin-top: 10px;
  }
  .terms_condition {
    // padding: 0px 50px;
    margin-top: 40px;
    text-align: left;
    .check_ {
      width: 100%;
      float: left;
    }

    button {
      // margin-left: 20px;
      width: 200px;
      height: 40px;
      background: $primarycolor 0% 0% no-repeat padding-box;
      border-radius: 40px;
      border: none;
      font: normal normal 'Roboto';
      font-size: 15px;
      letter-spacing: 0.32px;
      color: #eefff9;
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 768px) {
  .regjistro_biznesin {
    height: 100%;
    // padding: 0 40px;
    .logo {
      padding-top: 55px;
      img {
        width: 100px;
        height: 70px;
      }
    }
    h1 {
      // float: left;
      text-align: center;
      font-size: 35px;
      padding-left: 0;
      padding-top: 40px;
      margin-left: -40px;
    }
    .regjistro_form {
      padding: 0;
      .form_left,
      .form_right {
        max-width: 100% !important;
        margin: auto;
      }
    }
    .terms_condition button {
      margin-left: 0;
    }
    .menu_icon {
      padding-right: 10px;
    }
  }
}

@media screen and (max-width: 534px) {
  .regjistro_biznesin {
    .logo {
      padding-top: 73px;
      img {
        width: 100px;
        height: 60px;
      }
    }
    h1 {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 425px) {
  .regjistro_biznesin {
    padding-left: 15%;
    padding-top: 70px;
    .logo {
      padding-top: 63px;
      img {
        width: 100px;
        height: 60px;
      }
    }
    h1 {
      font-size: 20px;
      float: left;
      margin-left: -20px;
    }
    .terms_condition {
      padding: 0px 50px;
      text-align: left;

      button {
        margin-left: 0px;
        width: 170px;
        height: 40px;
        background: $primarycolor 0% 0% no-repeat padding-box;
        border-radius: 40px;
        border: none;
        font: normal normal 'Roboto';
        font-size: 15px;
        letter-spacing: 0.32px;
        color: #eefff9;
      }
      label {
        font-size: 12.7px;
      }
    }
    .regjistro_form {
      p {
        padding: 30px 0 0 5px;
      }
      .form_left {
        padding-right: 0;
      }
      .form_right {
        padding-left: 10px;
        padding-right: 0;
      }
      form {
        padding: 0;
      }
      .regjistrohu_textarea {
        padding: 30px 0 10px 10px;
      }
    }
    .terms_condition {
      padding: 0 0 0 10px;
    }

    .fjeshil-container .logo {
      padding: 0 0 0 3%;
    }
    .menu_icon {
      padding-right: 10px;
    }
  }
}
