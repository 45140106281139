$primarycolor: #0d381f;
$secondarycolor: #ed1944;
.scroll_btn {
  position: fixed;
  float: left;
  width: 100%;
  left: 93%;
  bottom: 25%;
  height: 20px;
  font-size: 3rem;
  z-index: 99;
  cursor: pointer;
  color: $primarycolor;
}

@media screen and (max-width: 1024px) {
  .scroll_btn {
    bottom: 35%;
  }
}

@media screen and (max-width: 599px) {
  .scroll_btn {
    left: 80%;
    bottom: 40%;
  }
}

@media screen and (max-width: 430px) {
  .scroll_btn {
    left: 87%;
    bottom: 30%;
    font-size: 2.7rem;
  }
}

@media screen and (max-width: 320px) {
  .scroll_btn {
    left: 75%;
    bottom: 30%;
    font-size: 2.7rem;
  }
}
