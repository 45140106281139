$primarycolor: #0d381f;
$secondarycolor: #ed1944;

.hero_section {
  // margin-bottom: 30px;
  .hero_image {
    // background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    //   url('../../images/hero_img.jpg');
    // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    display: block;
    width: 100%;
    height: 650px;
    // height: 610px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    // background: #173142 0% 0% no-repeat padding-box;
    // opacity: 0.83;
  }

  .hero_content {
    padding: 0 90px 0 150px;

    .row {
      margin-right: 0;
    }

    .menu_ {
      padding: 0 0 0 25px;

      .menu {
        // float: right;
        // margin-right: -30px;
        display: block;
        .facebook a,
        .instagram a,
        .twitter a {
          float: left;
          // color: #2aced7;
          color: $secondarycolor;
          padding-top: 70px;
          font-size: 30px;
          // display: inline-block;
          padding-right: 20px;
          :hover {
            color: $secondarycolor;
          }
        }
        // .facebook img {
        //   width: 52px;
        //   height: auto;
        //   margin-right: -7px;
        //   // margin-top: -2px;
        // }
        // .instagram {
        //   img {
        //     width: 40px;
        //     height: auto;
        //   }
        // }
        // .twitter img {
        //   width: 39px;
        //   height: auto;
        //   // padding-left: 5px;
        // }
      }
    }

    .hero_text {
      float: left;
      padding-top: 110px;

      .hero_logo {
        background-image: url('../../images/LOGO_RED.png');
        width: 370px;
        height: 100px;
        // width: 300px;
        // height: 300px;
        padding-bottom: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        float: left;
        margin-left: -53px;
      }

      p {
        text-align: left;
        font: normal normal normal 'Roboto';
        font-size: 20px;
        letter-spacing: 0.45px;
        color: #eefff9;
        opacity: 1;
        // width: 60%;
        padding-top: 5px;
        padding-left: 5px;
        float: left;
        width: 80%;
      }

      .btn {
        padding: 0;
        margin: 0;
        padding-left: 10px;
        float: left;
        // padding-top: 30px;

        // button {
        //   width: 230px;
        //   height: 50px;
        //   box-shadow: 0px 3px 6px #2aced7;
        //   border: 3px solid #2aced7;
        //   opacity: 1;
        //   background-color: transparent;
        //   border-radius: 40px;
        //   color: #eefff9;
        //   font: normal normal medium 20px/26px Roboto;
        //   letter-spacing: 0.32px;
        //   outline: none;
        // }

        .btn_playstore {
          margin-right: 10px;
        }
        a img {
          margin-top: -15px;
          width: 170px;
          height: auto;
        }
      }
    }
    .moto {
      margin-top: 140px;
      // margin: 0;
      // padding: 0;
      text-align: left;
      color: #fff;
      // font-weight: bold;
      // font-size: 20px;
      float: left;
      margin-left: -120px;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        // text-transform: uppercase;
        font-size: 32px;
        font: normal normal normal 30px/39px 'Roboto';
        // letter-spacing: 1px;
        font-weight: bold;
        // color: #2aced7;
        color: $secondarycolor;
      }
      p {
        font-size: 17px;
        color: #fff;
        font: normal normal normal 17.5px/29px 'Roboto';
      }
    }
  }
}

// MEDIA QUERIES

@media screen and (max-width: 1024px) {
  .hero_section {
    .hero_image {
      // background-size: auto;
      width: 100%;
      height: 650px;
      // background-size: 100% 100%;
    }
    .hero_content {
      padding: 0 30px 0 100px !important;

      .hero_text {
        p {
          // width: 70%;
          width: 95%;
          padding-left: 5px;
        }

        .btn {
          a img {
            margin-top: -30px;
            width: 160px;
            height: auto;
          }
        }
      }
      .moto {
        margin-top: 140px;
        margin-left: 0px;
      }
    }

    .container {
      max-width: 900px;
    }
  }
}

@media screen and (max-width: 768px) {
  .hero_section .hero_content {
    padding: 0 30px 0 70px !important;

    .hero_text {
      padding-top: 100px;

      .hero_logo {
        width: 300px;
        height: 90px;
        padding-bottom: 20px;
        // float: none;
        margin-left: -40px;
      }

      p {
        font-size: 17px;
        opacity: 1;
        width: 100%;
        padding: 10px 10px 0 5px;
      }

      .btn {
        padding-left: 10px;
        a img {
          width: 140px;
        }
      }
    }

    .moto {
      margin-top: 115px;
      margin-left: 0px;
      h1 {
        font-size: 25px;
      }
      p {
        font-size: 17px;
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .hero_section {
    .hero_image {
      height: 550px;
    }
    .hero_content {
      padding: 0 0px 0 20px !important;
      .menu_icon {
        padding-right: 20px;
        padding-top: 30px;
      }

      .menu_ {
        padding: 0 0 0 20px;
        .menu {
          .facebook a,
          .instagram a,
          .twitter a {
            // padding-top: 73px;
            // padding-left: 15px;
            font-size: 25px;
            padding-right: 10px;
          }
        }
      }

      .hero_text {
        padding-top: 130px;
        .hero_logo {
          width: 340px;
          height: 80px;
          padding-bottom: 20px;
          margin-left: -50px;
        }

        p {
          font-size: 17px;
          opacity: 1;
          width: 100%;
        }

        .btn {
          a img {
            margin-top: -20px;
            width: 130px;
            height: auto;
          }
        }
      }
      .moto {
        display: none;
      }
    }
  }
}
