$primarycolor: #0d381f;
$secondarycolor: #ed1944;
.terms {
  // padding: 57px 100px 150px 100px;
  .row {
    margin-right: 0;
  }
  .logo {
    padding: 0 70px 0px 100px;
    margin-bottom: 60px;
    background-color: $primarycolor;
    a img {
      width: 140px;
      height: auto;
      float: left;
      // margin-bottom: 60px;
    }
  }

  .tekst_konfirmimi {
    // text-align: center;
    padding: 0 100px 0 100px;
    h3,
    h5 {
      text-align: center;
      padding-bottom: 10px;
    }
    h5 {
      padding-bottom: 40px;
    }
    .my_list {
      .terms_list {
        font-weight: bolder;
        padding-bottom: 30px;
        p {
          font-weight: normal;
        }
      }
      .sub_list {
        font-weight: normal;
      }
    }
  }

  .navbar__ {
    .navbar {
      padding-top: 0;
      margin-top: 0;
      .menu-bars {
        // color: gray;
        color: #fff;
      }
    }
    .nav-menu {
      border-left: 1px solid #000;
    }
  }
}

@media screen and (max-width: 425px) {
  .terms {
    .tekst_konfirmimi {
      // text-align: center;
      padding: 0 40px 0 40px;
      h3 {
        font-size: 25px;
      }
      h5 {
        font-size: 17px;
      }
    }
    .logo {
      padding: 0 10px 0px 40px;
      margin-bottom: 60px;
      background-color: #0d381f;
    }
  }
}
